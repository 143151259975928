<template>
<div id="login">
  <div class="login-box">
    <div class="login-left">
      <router-link :to="{path:'/'}">
        <div class="des">
          <img src="@/assets/imgs/logo.png" />
          <p>
            <span>找工作</span>
            <label>上哈密人才招聘网直接谈</label>
          </p>
        </div>
      </router-link>
      <div class="des des-find">
        <img src="@/assets/imgs/personal/connectBtn.png" />
        <p>
          <span>沟通</span>
          <label>在线职位及时沟通</label>
        </p>
      </div>
      <div class="des des-find">
        <img src="@/assets/imgs/personal/selectBtn.png" />
        <p>
          <span>任性选</span>
          <label>各大行业职位任你选</label>
        </p>
      </div>
    </div>
    <div class="login-right" >
      <div class="switch" v-show="loginSub==2" @click="toggleLogin(1)">
        <i class="el-icon-monitor"></i>
        <span></span>
        <div class="switch-tip">验证码登录/注册</div>
      </div>
      <div class="switch" v-show="loginSub==1" @click="toggleLogin(2)">
        <i class="el-icon-lock"></i>
        <span></span>
        <div class="switch-tip">密码登陆/注册</div>
      </div>
      <div class="sms-wrap" v-show="loginSub!=3">
        <div class="lg-title" v-show="loginSub==1">验证码登录/注册</div>
        <div class="lg-title" v-show="loginSub==2">密码登陆/注册</div>
        <div class="lg-header">
          <ul>
            <li @click="toggle(1)" :class="{'active':sub==1}">我要找工作</li>
            <li @click="toggle(2)" :class="{'active':sub==2}">我要招聘</li>
            <li @click="toggle(3)" :class="{'active':sub==3}">商户</li>
          </ul>
        </div>
        <div class="lg-ct">
          <div class="phone-item">
            <el-select v-model="code" placeholder="请选择">
              <el-option v-for="item in codeList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-input class="phone" v-model="phone" placeholder="手机号"></el-input>
          </div>
          <el-input class="verCode" v-show="loginSub==1" v-model="verCode" placeholder="短信验证码"></el-input>
          <div class="countDown" v-show="loginSub==1" >
            <span v-show="!isDisabled" @click="smsCode">发送验证码</span>
            <span v-show="isDisabled">已发送{{count}}s</span>
          </div>
          <el-input class="verCode" type="password"  v-show="loginSub==2" auto-complete="new-password"  v-model="password" placeholder="请输入密码"></el-input>
          <div class="loginBtn" @click="login">登录/注册</div>
          <div class="wx" @click="toggleWx"><img src="@/assets/imgs/wxLogin.png" /><span>微信登录/注册</span></div>
        </div>
        <div class="protocol">
          <el-checkbox v-model="checked"></el-checkbox>
          <p>同意哈密人才招聘网
          <span @click="jumpAgree(1)">《用户协议》</span>
          <span @click="jumpAgree(2)">《隐私政策》</span></p>
        </div>
      </div>
      <div class="wxLogin" v-show="loginSub==3">
          <h3>微信扫码 安全登录</h3>
          <img :src="QRCodeInfo.base64Str" />
          <p>请使用微信扫描二维码登录“哈密人才招聘网”</p>
          <div class="return-btn" @click="toggleLogin(1)">验证码登录/注册</div>
      </div>
    </div>
  </div>
  <!-- 选择身份 -->
  <div class="identityBox">
    <el-dialog
      title="选择身份"
      :visible.sync="identityDialog"
      width="500px"
      >
      <p @click="toggleIdent(1)" :class="{activeIndent:subIndent==1}">
        <img src="@/assets/imgs/personal/job.png" />
        <span>求职者</span>
      </p>
      <p @click="toggleIdent(2)" :class="{activeIndent:subIndent==2}">
        <img src="@/assets/imgs/personal/company.png" />
        <span>企业</span>
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="identityDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitIndent">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</div>
</template>

<script>
import {
  getVerificationCode,
  getQRCode,
  login,
  selectIdentity,
  verifyRealName,
  getVerifyResumeFilling,
  getMerchantOrNot
} from "@/api/index.js";

export default {
  name: "login",
  data() {
    return {
      sub: 1,
      codeList: [{
        value: 1,
        label: '+86'
      }],
      code: 1,
      phone: '',
      password:'',
      verCode: '',
      count: 60,
      isDisabled: false,
      checked: true,
      loginSub:1,
      QRCodeInfo:{},
      identityDialog:false,
      subIndent:1
    }
  },
  mounted(){

  },
  methods: {
    toggleIdent(index){
      this.subIndent = index
    },
    jumpAgree(sub){
       this.$router.push({
        path:'/agreement',
        query:{
          sub:sub
        }
      })
    },
    submitIndent(){
      this.identityDialog = false
      selectIdentity({
        type:this.subIndent
      }).then(() => {
        if (this.subIndent === 1) {
          this.$router.push({
            path: '/resume'
          })
          localStorage.setItem('userInfo', JSON.stringify({ 'type': 1 }))
        }else if (this.subIndent === 2){
          this.$router.push({
            path: '/addContacts'
          })
          localStorage.setItem('userInfo', JSON.stringify({ 'type': 2 }))
        }else if (this.subIndent === 3){
          this.$router.push({
            path: '/applyInfo'
          })
          localStorage.setItem('userInfo', JSON.stringify({ 'type': 0 }))
        }

      })
    },
    initWebSocket(){
      var _this = this
      //心跳设置
      var data = {
        cmd:5,
        data: {
          webSocketId: this.QRCodeInfo.webSocketId
        }
      }
      var heartCheck = {
          timeoutObj: null,
          start(){
            SocketTask.send(JSON.stringify(data))
          },
           reset(){
            clearTimeout(heartCheck.timeoutObj);
            heartCheck.timeoutObj = setTimeout(function() {
              heartCheck.start();
            }, 10000);
          }
       }
      var SocketTask = new WebSocket( process.env.VUE_APP_BASE_WS)

      SocketTask.onopen = function () {
          heartCheck.start();
      }
      SocketTask.onmessage = function (evt) {
        let sendInfo = JSON.parse(evt.data)
        if(sendInfo.cmd==7){
          localStorage.setItem('token', sendInfo.data.token)

          if(sendInfo.data.type!=0){
             localStorage.setItem('userInfo',JSON.stringify({ 'type': sendInfo.data.type }))
             _this.$router.push({
              path: '/home'
            })
          }else{
            _this.identityDialog = true
          }
        }
        heartCheck.reset();
      }
      SocketTask.onclose = function () {
          console.log("连接已关闭...")
      }
    },
    getQRCode(){
      getQRCode().then(res=>{
        this.QRCodeInfo = res
        this.initWebSocket()
      })
    },
    toggleLogin(index){
      this.loginSub = index
    },
    toggle(index) {
      this.sub = index
    },
    toggleWx(){
      this.loginSub = 3
      this.getQRCode()
    },
    smsCode() {
      const TIME_COUNT = 60; //倒计时60秒
      if (!this.phone) {
        this.$message({
          message: '请输入手机号！！',
          type: 'warning'
        });
        return
      }
      if (!this.timer) {
        this.getSmsCode()
        this.count = TIME_COUNT;
        this.isDisabled = true;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.isDisabled = false;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    getSmsCode() {
      getVerificationCode({ phone: this.phone }).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '验证码发送成功',
            type: 'success'
          })
        } else {
          this.$message({
            message: res.msg,
            type: 'warning'
          })
        }

      })
    },
    login() {
      console.log(this.loginSub)
      var params = {}
      params.phone = this.phone
      if (!this.phone) {
        this.$message({
          message: '手机号码不能为空！！',
          type: 'warning'
        });
        return
      }
      if (!this.verCode&&this.loginSub==1) {
        this.$message({
          message: '验证码不能为空！！',
          type: 'warning'
        });
        return
      }
      if (!this.checked) {
        this.$message({
          message: '请阅读并同意哈密人才招聘网《用户协议》《隐私政策》',
          type: 'warning'
        });
        return
      }
      if (!this.password&&this.loginSub==2) {
        this.$message({
          message: '密码不能为空！！',
          type: 'warning'
        });
        return
      }
      this.loginSub==1 ?  params.smsCode = this.verCode:  params.password = this.password
      console.log(params)
      login(params).then(res => {
        if (res.code == 200) {
          localStorage.setItem('token', res.token)
          this.selectIdentity()
        } else {
          this.$message({
            message: res.msg,
            type: 'warning'
          });
        }
      })
    },
    verifyRealName() {
      verifyRealName({}).then(res => {
        if (res.isCertification == 1) {
          this.$router.push({
            path: '/home'
          })
        } else {
          this.$router.push({
            path: '/addContacts'
          })
        }
      })
    },
    selectIdentity() {
      selectIdentity({
        type: this.sub
      }).then(() => {
        if (this.sub === 1) {
          this.getVerifyResumeFilling()
          localStorage.setItem('userInfo', JSON.stringify({ 'type': 1 }))
        } else if (this.sub === 2) {
          this.verifyRealName()
          localStorage.setItem('userInfo', JSON.stringify({ 'type': 2 }))
        }else if(this.sub === 3){
          this.getOddJobMerchant()
          localStorage.setItem('userInfo', JSON.stringify({ 'type': 0 }))
        }

      })
    },
    getVerifyResumeFilling() {
      getVerifyResumeFilling().then(res => {
        console.log(res)
        if (res.data) {
          this.$router.push({
            path: '/home'
          })
        } else {
          this.$router.push({
            path: '/resume'
          })
        }
      })
    },
    //零工商户
    getOddJobMerchant() {
      getMerchantOrNot().then(res=>{
        console.log(res)
        if(res.data != undefined){
          if (!res.data.merchantOrNot) {
            this.$router.push({
              path: '/applyInfo'
            })
          } else {
            this.$router.push({
              path: '/merchant'
            })
          }
        }
      })
    }

  }
};
</script>

<style lang="scss" scoped>
#login {
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: url('../assets/imgs/loginBg.png');
  background-size: cover;
}

.login-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 728px;
  height: 520px;
  margin-left: -364px;
  margin-top: -260px;
  background: #fff;
  box-shadow: 0 6px 13px 0 rgba(0, 0, 0, .1);
  border-radius: 28px;
  z-index: 2;
  box-sizing: border-box;
}

.login-left {
  float: left;
  width: 240px;
  height: 100%;
  background: linear-gradient(214deg, #dffbff, #faf6f3);
  border-radius: 28px 0 0 28px;
  padding-left: 32px;
  font-size: 14px;
  box-sizing: border-box;
  padding-top: 68px;

  .des {
    height: 42px;

    img {
      width: 42px;
      height: 42px;
      float: left;
    }

    p {
      margin-left: 14px;
      float: left;

      span {
        display: block;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        color: #0077FF;
        line-height: 20px;
      }

      label {
        display: inline-block;
        font-size: 14px;
        color: #0077FF;
        line-height: 20px;
        margin-top: 2px;

      }
    }
  }

  .des-find {
    clear: both;
    margin-top: 50px;

    img {
      width: 33px;
      height: 33px;
      margin-left: 4px;
    }

    p {
      margin-left: 19px;

      span {
        color: #666;
      }

      label {
        font-size: 13px;
        color: #999;

      }
    }
  }

}
.switch{
  position: relative;
  cursor: pointer;
  i{
    font-size: 38px;
    color: #999;
    margin: 8px;
    cursor: pointer;
  }
  span{
    display: inline-block;
    position: absolute;
    left: -20px;
    top:6px;
    height: 0px;
    width: 0px;
    border-top: 40px solid transparent;
    border-right: 40px solid #fff;
    border-left: 40px solid transparent;
  }
  .switch-tip{
    display: inline-block;
    position: absolute;
    top: 14px;
    left: 60px;
    padding: 0 14px;
    border-radius: 4px;
    font-size: 12px;
    color: #666;
    line-height: 32px;
    text-align: center;
    background: #fff;
    box-shadow: 0 6px 13px 0 rgba(0,0,0,.1);
    white-space: nowrap;
    z-index: 999;
  }
  .switch-tip::before{
    content: "";
    position: absolute;
    left: -5px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    width: 10px;
    height: 10px;
    background-color: #fff;
    z-index: -1;
    box-shadow: 0 6px 13px 0 rgba(0,0,0,.1);

  }
}
.switch:hover{
  i{
    color: #0077FF;
  }
}
.login-right {
  float: left;
  float: left;
  position: relative;
  width: 485px;
  .sms-wrap{
    width: 390px;
    margin: 10px 0 20px 45px;
  }
  .lg-title {
    font-size: 22px;
    font-weight: 500;
    color: #222;
    line-height: 30px;
    text-align: center;
    margin-bottom: 30px;
  }

  .lg-header {
    margin-bottom: 28px;
    margin-top: 28px;
    padding: 4px;
    background: #f8f8f8;
    border-radius: 12px;
    overflow: hidden;


    li {
      float: left;
      width: 33%;
      height: 40px;
      font-size: 16px;
      color: #444;
      line-height: 40px;
      text-align: center;
      border-radius: 8px;
      cursor: pointer;
      transition: all .2s linear;

    }
  }

  .active {
    background: #fff !important;
    color: #0077FF !important;
  }

  .lg-ct {
    margin: 28px auto 0;
    position: relative;
  }

  .phone-item {
    border: 1px solid #d0d2d9;
    border-radius: 8px;
  }

  .countDown {
    position: absolute;
    top: 88px;
    right: 23px;
    font-size: 16px;
    color: #0077FF;
    cursor: pointer;
  }

  .loginBtn {
    display: block;
    background: #0077FF;
    border-radius: 8px;
    line-height: 48px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    border: none;
    cursor: pointer;
    color: #fff;
    padding: 0;
    margin-top: 30px;
    transition: all .2s linear;
  }

  .protocol {
    margin: 0 auto;

    p {
      display: inline-block;
      font-size: 12px;
      color: #666;
      line-height: 28px;
      margin-left: 10px;
      margin-top: 20px;
    }
    span{
      color: #0077FF;
      cursor: pointer;
    }
  }

}
.wx{
  text-align: center;
  margin: 20px auto 0;
  img{
    width: 24px;
    height: 24px;
    position: relative;
    top: 5px;
    margin-right: 7px;
  }
  span{
    font-size: 13px;
    color: #666;
    line-height: 17px;
    transition: all .2s linear;
    cursor: pointer;
  }
  span:hover{
    color: #0077FF;
  }
}
.wxLogin{
  text-align: center;
  h3{
    font-size: 22px;
    font-weight: 500;
    color: #222;
    line-height: 30px;
    text-align: center;
    margin-bottom: 32px;
    margin-top: 48px;
  }
  img{
    width: 220px;
    height: 220px;
  }
  p{
    font-size: 13px;
    color: #999;
    line-height: 17px;
    margin-top: 30px;
  }
  .return-btn{
    display: block;
    margin: 32px auto 0;
    width: 160px;
    height: 32px;
    background: #f2f2f2;
    border-radius: 8px;
    text-align: center;
    line-height: 32px;
    font-size: 14px;
    color: #333;
    transition: all .2s linear;
    cursor: pointer;
  }
  .return-btn:hover{
    background: #0077FF;
    color: #fff;
  }
}
.identityBox{
  p{
    width:200px;
    height: 100px;
    float: left;
    text-align: center;
    margin-left:20px ;
    background: #F3F3F3;
    border-radius: 10px;
    line-height: 100px;
    cursor: pointer;

    img{
      width: 55px;
      height:55px;
      float: left;
      margin-top: 22px;
      margin-left: 25px;
    }
    span{
      display: block;
      float: left;
      margin-left: 18px;
      font-size: 24px;
      color: #222222;
    }
  }
  p:hover{
    background: #fff;
    border: 1px solid #0077FF;
  }
  .activeIndent{
     background: #fff;
     border: 1px solid #0077FF;
  }
}
</style><style lang="scss">
.lg-ct {
  .phone {
    width: 290px;

    .el-input__inner {
      font-size: 16px;
      height: 46px;
      border: none;
      padding-right: 20px;
      box-sizing: border-box;
      border-radius: 0 8px 8px 0;
      border-left: none;
    }
  }

  .verCode {
    display: block;
    margin-top: 28px;
    margin-bottom: 28px;

    .el-input__inner {
      border: 1px solid #d0d2d9;
      font-size: 16px;
      height: 46px;
      padding-right: 20px;
      box-sizing: border-box;
      border-radius: 8px;

    }
  }

  .el-select {
    width: 90px;

    .el-input__inner {
      border: none;
      height: 46px;
      padding-left: 18px;
      border-radius: 8px 0 0 8px;
      box-sizing: border-box;
      transition: all .2s linear;
      border-right: none !important;
    }
  }

}
.identityBox{
  .el-dialog__body{
    overflow: hidden;
    padding: 20px;
  }
  .el-dialog__footer{
    text-align: center;
  }
  .el-button--primary{
    background: #0077FF;
    border: none;
  }
}

</style>
